import React from "react";
import { Typography } from "@mui/material";

import Layout from "../layout";

const IndexPage = ({pageContext}) => {

    return (
        <Layout>
            <Typography variant="h1">Coming Soon</Typography>
        </Layout>
    );
}

export default IndexPage;